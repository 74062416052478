import type { InstanceName } from './warehouse';
import type { User, KeyCookieCurrentUser } from '../server/cookie';
import { instanceName } from './warehouse';
import type { Ref } from 'vue';
import i18nLanguages from '@cospired/i18n-iso-languages/index.js';
import type { LocalizedLanguageNames } from '@cospired/i18n-iso-languages/index.js';
import enLang from '@cospired/i18n-iso-languages/langs/en.json';
import esLang from '@cospired/i18n-iso-languages/langs/es.json';
import frLang from '@cospired/i18n-iso-languages/langs/fr.json';
import itLang from '@cospired/i18n-iso-languages/langs/it.json';
import type { Composer } from 'vue-i18n';

export type Lang = 'en' | 'fr' | 'es' | 'it';
export type Locale = 'en-US' | 'fr-CA' | 'es-ES' | 'it-IT';

export const i18n = (): Composer => useNuxtApp().$i18n as Composer;
/**
 * Mapping list for a default locale by instance
 * @returns {object} { eden: "fr-CA", cant: "en-US", ... }
 */
const DEFAULT_INSTANCE_LOCALES: { [key in InstanceName]: Locale } = {
  csco: 'fr-CA',
  eden: 'fr-CA',
  edgt: 'it-IT',
  enqc: 'fr-CA',
  cant: 'en-US',
  libr: 'es-ES',
};

/**
 * Mapping list for a label by locale
 * @returns {object} { "fr-CA": "Français", "en-US": "English", ... }
 */
export const AVAILABLE_LOCALES_LABELS: { [key in Locale]: string } = {
  'fr-CA': 'Français',
  'en-US': 'English',
  'es-ES': 'Español',
  'it-IT': 'Italiano',
};

/**
 * Available locales codes (2 chars for language - 2 chars for region)
 * @returns {String[]} ["fr-CA","en-US",...]
 */
const availableLocales = Object.keys(AVAILABLE_LOCALES_LABELS) as Locale[];

/**
 * Available languages codes (2 chars for language)
 * @returns {String[]} ["fr", "en", ...]
 */
export const availableLanguages: string[] = availableLocales.map((key) => key.split('-')[0]);

/**
 * Set app locale (i18n.locale.value) in the following orders
 * - set locale from path query string (eg: ?l=en)
 * - set locale from the current user locale set into the instance cookie
 * - set locale from the instance set into the cookie
 * If none of these conditions changes the locale,
 * then it uses the one set by default in the nuxt config
 */
export const setLocale = (): void => {
  /**
   * Get the default locale set from the instance configuration
   */
  const defaultInstanceLocale: string = DEFAULT_INSTANCE_LOCALES[instanceName()];

  /**
   * Find an available locale via a code passed
   * try to fetch a 5 chars code (eg: en-US) first
   *
   * if not found, it will fallback on the 2 chars code (eg: en)
   * and will return the 5 code letters equivalent (eg: en-US)
   *
   * @param {string} l a 2 to 5 valid char code (eg: 'en' or 'en-US' )
   * @returns {string | null} a 5 chars code (eg: en-US)
   */
  const findClosestLocale = (l: Locale): string | null => {
    if (availableLocales.includes(l)) return l;
    const index = availableLanguages.indexOf(l.split('-')[0]);
    return (index != -1) ? availableLocales[index] : null;
  };

  /**
   * Find the query strings from the route
   * @returns {string} query string (eg l=en&variable=hello&...)
   */
  const findQueryStrings = (): string => {
    const isSSR = typeof window === 'undefined';
    if (isSSR) {
      const route = useRoute();
      return route.fullPath.split('?')[1];
    } else {
      return window.location.search;
    }
  };

  /**
   * Try to assign the locale found from the route to the default locale (i18n)
   * @returns {boolean} true if success
   */
  const setLocaleFromPath = (): boolean => {
    const path = findQueryStrings();
    const urlParams = new URLSearchParams(path);
    const locale = urlParams.get('l') as Locale;
    if (!locale) return false;

    const closestLocale = findClosestLocale(locale);
    if (!closestLocale) return false;

    i18n().locale.value = closestLocale;
    return true;
  };

  /**
   * Try to assign the locale found from the user cookie.language field
   * to the default locale (i18n)
   * @returns {boolean} true if success
   */
  const setLocaleFromCookie = (): boolean => {
    const language = getCookieData('language') as Locale;
    if (!language) return false;

    const closestLocale = findClosestLocale(language);
    if (!closestLocale) return false;

    i18n().locale.value = closestLocale;
    return true;
  };

  /**
   * Try to assign the locale found from the current user cookie instance
   * based on his language field to the default locale (i18n)
   * @returns {boolean} true if success
   */
  const setLocaleFromCurrentUser = (): boolean => {
    const user = currentUser();
    if (!user.exists) return false;

    const closestLocale = findClosestLocale(user.language as Locale);
    if (!closestLocale) return false;

    i18n().locale.value = closestLocale;
    return true;
  };

  /**
   * Try to assign the locale found in the cookie based on the instance name
   * to the default locale (i18n)
   * @returns {boolean} true if success
   */
  const setLocaleFromInstance = (): boolean => {
    const l = defaultInstanceLocale;
    if (!l) return false;

    i18n().locale.value = l;
    return true;
  };

  /**
   * This method has been disabled as I don't know yet if this could be useful or no
   * keep it for now
   *
   * Try to assign the locale from the Browser to the default locale (i18n)
   * @returns {boolean} true if success
   */
  // const setLocaleFromBrowser = (): boolean => {
  //   if (isSSR) return false

  //   const locale = navigator.language
  //   const closestLocale = findClosestLocale(locale)
  //   if (!closestLocale) return false

  //   i18n.locale.value = closestLocale
  //   return true
  // }

  /**
   * This method could be interesting if we trusted our translations 100%
   * For now I think keeping only one fallback locale is the best we could do
   * This will use the one set by default in the nuxt config
   * keep it for now
   *
   * Try to assign a locale found in the cookie based ont the instance name
   * to the default fallback locale (i18n)
   * @returns {boolean} true if success
   */
  // const setFallbackFromInstance = (): boolean => {
  //   if (!instanceName) return false

  //   const l = findDefaultInstanceLocale()
  //   if (!l) return false

  //   i18n.fallbackLocale.value = l
  //   return true
  // }

  setLocaleFromPath() ||
    setLocaleFromCookie() ||
    setLocaleFromCurrentUser() ||
    setLocaleFromInstance(); //||
  // setLocaleFromBrowser()

  updateCookieLanguage(i18n().locale.value.slice(0, 2) as Lang);

  // setFallbackFromInstance()
};

/**
 * Update the user cookie instance language field (private space)
 * and also the user cookie language field (public space)
 * @param {string} locale 2 chars code (eg: en)
 */
export const updateCookieLanguage = (locale: Lang): void => {
  const cookie = useHCookie();
  const cookieData = cookie.value;
  if (!cookieData) return;

  cookieData.language = locale;
  const instanceName = cookieData.instance_name;
  if (instanceName) {
    const key = `current_user_${instanceName}` as KeyCookieCurrentUser;
    const currentUser = cookieData[key] as User;
    if (currentUser) currentUser.language = locale;
  }
  cookie.value = cookieData;
};

/**
 * Update the price format depending on the detected local language
 */
export const localizeAmount = (amount: number | string): string => {
  const localLang = i18n().locale.value.substring(0, 2);

  return new Intl.NumberFormat(
    localLang, { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(amount),
  );
};

/**
 * Update the local language depending on the detected local language
 */
interface UseLanguage {
  language: Ref<Lang>;
}
export const useLanguage = (): UseLanguage => {
  const language = ref<Lang>(i18n().locale.value.slice(0, 2) as Lang);

  return {
    language,
  };
};

/*
 * Output all the available language names for the current user language
 * @return LocalizedLanguageNames list of languages
 */
export const useLanguageList = (): LocalizedLanguageNames => {
  const { language } = useLanguage();

  const langs = { en: enLang, es: esLang, fr: frLang, it: itLang };
  i18nLanguages.registerLocale(langs[language.value]);
  return i18nLanguages.getNames(language.value);
};

/*
 * Output all the available language names for a given Locale
 * @return LocalizedLanguageNames list of languages
 * @param {Locale} locale 5 chars code (eg:'en-US')
 */
export const getLanguageList = (locale: Locale): LocalizedLanguageNames => {
  const language = locale.slice(0, 2) as Lang;
  const langs = { en: enLang, es: esLang, fr: frLang, it: itLang };
  i18nLanguages.registerLocale(langs[language]);
  return i18nLanguages.getNames(language);
};

export const parseLanguage = (language: string): string | undefined => {
  return i18nLanguages.alpha2ToAlpha3B(language)?.toUpperCase();
};
